import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import Wrapper from "../../routes/Wrapper";
import Timer from "../../components/Timer";
import SuccessWalletModal from "../../components/SuccessWalletModal";
import Buy from "../../components/BuyToken/Buy";
import TrxInProgress from "../../components/BuyToken/TrxInProgress";
import PurchaseSuccessful from "../../components/BuyToken/PurchaseSuccessful";
import PurchaseUnsuccessful from "../../components/BuyToken/PurchaseUnsuccessful";
import { useAccount } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { zeroAddress } from "viem";
import { RPC, icoABI, icoAddress, usdt, usdtABI } from "../../utils/constant";
import UnSuccessWalletModal from "../../components/UnSuccessWalletModal";
import { ethers } from "ethers";
const { ethereum } = window;
const BuyToken = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [step, setStep] = useState(0);
  const [round, setRound] = useState(0);
  const [receipt, setReceipt] = useState();
  const [isWhiteList, setIsWhiteListed] = useState();
  const { isConnected, address, chainId } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [icoContract, setIcoContract] = useState();
  const [usdtContract, setUsdtContract] = useState();
  const tokenList = [
    { label: "ETH", icon: "./images/ethIcon.svg", address: zeroAddress },
    { label: "USDT", icon: "./images/ustdIcon.svg", address: usdt },
    {
      label: "CARD",
      icon: "./images/cardIcon.svg",
      address: usdt,
      catagory: "card",
    },
  ];
  const [selectedToken, setSelectedToken] = useState(tokenList[0]);

  const getContract = (
    signer = false,
    contractAddress,
    contractAbi,
    setContract
  ) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      contract = new ethers.Contract(contractAddress, contractAbi, signer);
    } else {
      contract = new ethers.Contract(contractAddress, contractAbi, provider);
    }

    setContract(contract);
  };

  const getICORound = async () => {
    try {
      const res = await icoContract.getRound();
      setRound(res?.toString());
    } catch (err) {
      console.log(err);
    }
  };

  ///Getting Contract
  useEffect(() => {
    if (isConnected) {
      getContract(true, icoAddress, icoABI, setIcoContract);
      getContract(true, usdt, usdtABI, setUsdtContract);
    } else {
      getContract(false, icoAddress, icoABI, setIcoContract);
    }
  }, [isConnected, chainId]);

  useEffect(() => {
    if (icoContract) getICORound();
  }, [icoContract]);

  return (
    <Wrapper>
      <div className="lading-page bg-[#0c2645] flex w-full p-5">
        <div className="wrap flex items-center justify-center w-full">
          <div className="flex w-full">
            <div className="flex flex-1 flex-col w-full">
              <div className="token-box w-full flex flex-col">
                <h1 className="text-white text-center font-semibold text-3xl mb-3 text-[24px] md:text-[36px]">
                  {round === "1"
                    ? "SEED ROUND OPEN"
                    : round === "2"
                    ? "PRE-SALE ROUND OPEN"
                    : round === "3"
                    ? "PUBLIC-SALE ROUND OPEN"
                    : "Fetching Round...."}
                </h1>
                <h1 className="text-themeColor2 text-center text-3xl text-[20px] md:text-[24px]">
                  Buy in Before Price Increase
                </h1>
                <div className="flex flex-col items-center justify-center my-6 gap-6">
                  <Timer />
                  <div className="progress-bar flex overflow-hidden">
                    <div
                      className="inner-bar text-[#161819] font-bold text-[10px] px-2"
                      style={{ width: "47.3%" }}
                    >
                      UNTIL PRICE INCREASE
                    </div>
                  </div>
                  <h1 className="text-white font-semibold text-[14px] md:text-[18px]">
                    USDT Raised $473,345 / $1,000,000
                  </h1>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center justify-center relative w-full gap-5">
                    <div className="border-b border-[#A9A9A9] flex flex-1" />
                    <h1 className="text-white text-[12px] md:text-[14px] font-medium text-center z-10">
                      1 QOR TOKEN ={" "}
                      {round === "1"
                        ? "$0.125"
                        : round === "2"
                        ? "$0.2"
                        : round === "3"
                        ? "$0.35"
                        : "$0.0"}
                    </h1>
                    <div className="border-b border-[#A9A9A9] flex flex-1" />
                  </div>
                  <div className="flex items-center justify-between gap-2 my-6">
                    {tokenList.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-1 items-center justify-center gap-1 bg-white p-2 rounded-sm border-2 border-transparent hover:border-themeColor2 cursor-pointer anim h-[50px] md:h-[60px]"
                        onClick={(e) => setSelectedToken(item)}
                      >
                        <img
                          src={item.icon}
                          className=" h-6 w-6 object-contain"
                          alt="icon"
                        />
                        <h1 className="text-[#10203E] font-medium text-[16px]">
                          {item.label}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                {
                  <div
                    className={step === 1 ? "flex flex-col w-full" : "hidden"}
                  >
                    <TrxInProgress></TrxInProgress>
                  </div>
                }
                {
                  <div
                    className={step === 2 ? "flex flex-col w-full" : "hidden"}
                  >
                    <PurchaseSuccessful
                      setStep={setStep}
                      step={step}
                      receipt={receipt}
                    ></PurchaseSuccessful>
                  </div>
                }
                {
                  <div
                    className={step === 3 ? "flex flex-col w-full" : "hidden"}
                  >
                    <PurchaseUnsuccessful
                      setStep={setStep}
                    ></PurchaseUnsuccessful>
                  </div>
                }

                {address ? (
                  <div
                    className={step === 0 ? "flex flex-col w-full" : "hidden"}
                  >
                    <Buy
                      setStep={setStep}
                      setIsWhiteListed={setIsWhiteListed}
                      selectedToken={selectedToken}
                      setOpenSuccess={setOpenSuccess}
                      setRound={setRound}
                      round={round}
                      setReceipt={setReceipt}
                      icoContract={icoContract}
                      usdtContract={usdtContract}
                    ></Buy>
                  </div>
                ) : (
                  <button
                    className="btn bg-themeColor2 text-[#161819] font-semibold text-base h-[52px] md:h-[60px]"
                    onClick={openConnectModal}
                  >
                    CONNECT WALLET
                  </button>
                )}
              </div>
              {address ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="dot h-3 w-3 bg-themeColor2 rounded-full"></div>
                  <label className="text-[#D9D9D9] font-normal text-[14px] md:text-[18px]">
                    Wallet Connected
                  </label>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-2">
                  <div className="dot h-3 w-3 bg-[#FF0F00] rounded-full"></div>
                  <label className="text-[#D9D9D9] font-normal text-[14px] md:text-[18px]">
                    No Wallet Connected
                  </label>
                </div>
              )}
              {/* <div className="flex items-center justify-center gap-2">
                <label className="text-[#D9D9D9] font-normal text-[14px] md:text-[18px]">
                  {isWhiteList === "true"
                    ? "You are whitelisted"
                    : isWhiteList === "false"
                    ? "You are not a whitelisted"
                    : ""}
                </label>
              </div> */}
            </div>
          </div>
        </div>
        {/* <Modal open={open} onClose={() => setOpen(false)}>
            <ChooseWalletModal
              setOpen={setOpen}
              setSelectedWallet={setSelectedWallet}
            />
          </Modal> */}
        <Modal open={isWhiteList === "false"}>
          <UnSuccessWalletModal />
        </Modal>
        <Modal open={openSuccess}>
          <SuccessWalletModal setOpenSuccess={setOpenSuccess} />
        </Modal>
      </div>
    </Wrapper>
  );
};

export default BuyToken;
