import React from "react";
import { CloseIcon } from "../assets/Icons";
import { Link } from "react-router-dom";
const ChooseWalletModal = ({ setOpen, setSelectedWallet }) => {
  const walletList = [
    { label: "MetaMask", icon: "./images/MetaMaskIcon.svg" },
    { label: "Coinbase Wallet", icon: "./images/CoinbaseIcon.svg" },
    { label: "Wallet Connect", icon: "./images/WalletConnectIcon.svg" },
  ];
  return (
    <div className="choose-wallet-modal flex flex-col">
      <div className="flex items-center justify-end">
        <button
          className="flex items-center justify-center"
          onClick={(e) => setOpen(false)}
        >
          <CloseIcon />
        </button>
      </div>
      <h1 className="text-white text-xl font-medium uppercase text-center mt-10">
        CHOOSE YOUR WALLET
      </h1>
      <div className="flex flex-col w-full my-6 gap-3">
        {walletList.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between gap-2 bg-white p-3 rounded-md border border-[#E9E9E9] cursor-pointer hover:border-themeColor2 anim"
            onClick={(e) => {
              setSelectedWallet(item);
              setOpen(false);
            }}
          >
            <h1 className="text-black text-xl font-semibold">{item.label}</h1>
            <img src={item.icon} className="h-8 w-8" alt="icon"/>
          </div>
        ))}
      </div>
      <Link
        to="https://ethereum.org/en/wallets/find-wallet/"
        target="_blank"
        className="btn border border-[#eee] bg-[#0f1e35] py-4 mt-4 text-center"
        onClick={(e) => setOpen(false)}
      >
        I don’t have a wallet
      </Link>
    </div>
  );
};

export default ChooseWalletModal;
