import React from 'react';

const TrxInProgress = () => {

    return (
        <div className='grid place-content-center my-16'>
            <div className='flex justify-center mb-4'>
                <img
                    src="./images/spiner.png"
                    className="h-10 w-10 object-contain animate-spin"
                    alt="icon"
                />
            </div>
            <h1 className='capitalize font-bold text-center text-white text-[20px] md:text-[24px]'>TRANSACTION IN PROGRESS....</h1>
            <p className='text-[14px] md:text-[18px] font-normal text-center text-white'>Thank you for purchasing QOR tokens<br></br> and joining the Qorra family! </p>
        </div>
    );
};

export default TrxInProgress;