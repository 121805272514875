import React from 'react';

const PurchaseUnsuccessful = ({setStep}) => {
    return (
        <div className='w-full'>
            <div className='flex justify-center mb-4'>
                <img
                    src="./images/close.png"
                    className="h-14 w-14 object-contain"
                    alt="icon"
                />
            </div>
            <h1 className='capitalize text-[20px] font-bold text-center text-white md:text-[24px]'>PURCHASE UNSUCCESSFUL</h1>
            <p className='text-[14px] md:text-[18px] font-normal text-center text-white'>We noticed that your transaction was not completed. If this was an error, please try<br></br> again. We're here to help if you encounter any issues. Your security and satisfaction are<br></br> our top priorities. Thank you for your interest in Qorra tokens!</p>
            <div className='flex flex-col gap-5 w-full mt-5'>
                <button onClick={()=>setStep(0)} className="btn bg-themeColor2 text-[#161819] font-semibold text-base h-[52px] md:h-[60px] block w-full">
                    Try Again
                </button>
            </div>
        </div>
    );
};

export default PurchaseUnsuccessful;