import React from "react";

const SuccessWalletModal = ({ setOpenSuccess }) => {
  return (
    <div className="choose-wallet-modal flex flex-col">
      <div className="flex items-center justify-end">
      </div>
      <div className="flex flex-col items-center justify-center gap-1 mt-20">
        <img
          src="./images/checkIcon.png"
          className="h-12 w-12 object-contain"
          alt="check Icon"
        />
        <h1 className="text-white text-xl font-medium uppercase text-center">
          CONGRATULATIONS
        </h1>
        <p className="text-white font-normal text-sm">
          Your wallet is whitelisted for the token sale.
        </p>
      </div>
      <button
        className="btn text-[#161819] uppercase bg-themeColor2 py-4 mt-4 text-center"
        onClick={() => setOpenSuccess(false)}
      >
        BUY NOW
      </button>
    </div>
  );
};

export default SuccessWalletModal;
