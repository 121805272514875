import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import Modal from "../../components/Modal";
import Wrapper from "../../routes/Wrapper";
import Timer from "../../components/Timer";
import ChooseWalletModal from "../../components/ChooseWalletModal";
import SuccessWalletModal from "../../components/SuccessWalletModal";
import UnSuccessWalletModal from "../../components/UnSuccessWalletModal";

const Main = () => {
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");

  const tokenList = [
    { label: "ETH", icon: "./images/ethIcon.svg" },
    { label: "USDT", icon: "./images/ustdIcon.svg" },
    { label: "CARD", icon: "./images/cardIcon.svg" },
  ];
  const [selectedToken, setSelectedToken] = useState(tokenList[0]);

  useEffect(() => {
    if (selectedWallet !== "") {
      setOpenSuccess(true);
    }
  }, [selectedWallet]);
  return (
    <Wrapper>
      <div className="lading-page bg-themeColor flex w-full">
        <div className="wrap flex items-center justify-center w-full">
          <div className="flex w-full">
            {/* <div className="flex flex-1 flex-col">
              <h1 className="text-white sm:text-5xl text-2xl font-medium">
                Embark on the Journey to Digital Asset Security
              </h1>
              <h3 className="text-themeColor2 text-xl font-medium mt-2">
                Round 1 Pre-Seed Closed
              </h3>
              <div className="flex mb-10 mt-5">
                <Timer />
              </div>
              <a
                href="https://qorra.gitbook.io/qorra-token-white-paper"
                target="_blank"
                className="bnt button !bg-themeColor2 w-max !text-themeColor uppercase font-semibold text-sm"
              >
                Read our Whitepaper
              </a>
            </div> */}
            <div className="flex flex-1 flex-col w-full">
              <div className="token-box w-full flex flex-col">
                <h1 className="text-white text-center font-semibold mb-3 text-[24px] md:text-[36px]">
                  SEED ROUND OPEN
                </h1>
                <h1 className="text-themeColor2 text-center text-[20px] md:text-[24px]">
                  Buy in Before Price Increase
                </h1>
                <div className="flex flex-col items-center justify-center my-6 gap-6">
                  <Timer />
                  <div className="progress-bar flex overflow-hidden">
                    <div
                      className="inner-bar text-[#161819] font-semibold text-[10px] px-2"
                      style={{ width: "47.3%" }}
                    >
                      UNTIL PRICE INCREASE
                    </div>
                  </div>
                  <h1 className="text-white font-semibold text-[14px] md:text-[18px]">
                    USDT Raised $473,345 / $1,000,000
                  </h1>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center justify-center relative w-full gap-5">
                    <div className="border-b border-[#A9A9A9] flex flex-1" />
                    <h1 className="text-white text-[12px] md:text-[14px] font-medium text-center z-10">
                      1 QOR TOKEN = $0.125
                    </h1>
                    <div className="border-b border-[#A9A9A9] flex flex-1" />
                    {/* <div className="border-b border-[#A9A9A9] w-full absolute "></div> */}
                  </div>
                  <div className="flex items-center justify-between gap-2 my-6">
                    {tokenList.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-1 items-center justify-center gap-1 bg-white p-2 rounded-sm border-2 border-transparent hover:border-themeColor2 cursor-pointer anim h-[50px] md:h-[60px]"
                        onClick={(e) => setSelectedToken(item)}
                      >
                        <img
                          src={item.icon}
                          className=" h-6 w-6 object-contain"
                          alt="icon"
                        />
                        <h1 className="text-[#10203E] font-medium text-[16px]">
                          {item.label}
                        </h1>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-1 flex-col gap-1">
                      <label className="text-white text-[14px] md:text-[18px]">
                        Pay with {selectedToken.label}
                      </label>
                      <div className="flex items-center gap-2 bg-white rounded-md p-2">
                        <input
                          type="number"
                          placeholder="0"
                          className="cleanbtn w-full h-[35px] md:h-[42px]"
                        />
                        <img
                          src={selectedToken.icon}
                          className="h-6 w-6 object-contain"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col gap-1">
                      <label className="text-white text-[14px] md:text-[18px]">Receive QOR</label>
                      <div className="flex items-center gap-2 bg-white rounded-md p-2">
                        <input
                          type="number"
                          placeholder="0"
                          className="cleanbtn w-full h-[35px] md:h-[42px]"
                        />
                        <img
                          src="./images/qorIcon.svg"
                          className="h-6 w-6 object-contain"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-16 gap-4">
                  <button
                    className="btn bg-themeColor2 text-[#161819] font-semibold text-base h-[52px] md:h-[60px]"
                    onClick={(e) => setOpen(true)}
                  >
                    CONNECT WALLET
                  </button>
                  <div className="flex items-center justify-center gap-2">
                    <div className="dot h-3 w-3 bg-[#FF0F00] rounded-full"></div>
                    <label className="text-[#D9D9D9] font-normal text-[14px] md:text-[18px]">
                      No Wallet Connected
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ChooseWalletModal
            setOpen={setOpen}
            setSelectedWallet={setSelectedWallet}
          />
        </Modal>
        <Modal open={openSuccess} onClose={() => setOpenSuccess(false)}>
          <SuccessWalletModal
            setOpenSuccess={setOpenSuccess}
            setSelectedWallet={setSelectedWallet}
          />
        </Modal>
        {/* <Modal open={openSuccess} onClose={() => setOpenSuccess(true)}>
          <UnSuccessWalletModal
            setOpenSuccess={setOpenSuccess}
            setSelectedWallet={setSelectedWallet}
          />
        </Modal> */}
      </div>
    </Wrapper>
  );
};

export default Main;
