import React from "react";
// import { CloseIcon } from "../assets/Icons";
import { Link } from "react-router-dom";

const UnSuccessWalletModal = () => {
  return (
    <div className="choose-wallet-modal flex flex-col">
      {/* <div className="flex items-center justify-end">
                <button
                    className="flex items-center justify-center"
                    onClick={(e) => setOpenSuccess(false)}
                >
                    <CloseIcon />
                </button>
            </div> */}
      <div className="flex flex-col items-center justify-center gap-1 mt-20">
        <img
          src="./images/close.png"
          className="h-12 w-12 object-contain"
          alt="check Icon"
        />
        <h1 className="text-white text-xl font-medium uppercase text-center">
          UNFORTUNATELY
        </h1>
        <p className="text-white font-normal text-sm text-center">
          Your wallet is not Whitelisted. Please join our Telegram group for
          early access to the next presale round.
        </p>
      </div>
      <Link
        to="https://t.me/Qorra_Announcements"
        className="btn text-[#161819] uppercase bg-themeColor2 py-4 mt-4 text-center"
        target="_blank"
        // onClick={(e) => setOpenSuccess(true)}
      >
        Join Telegram
      </Link>
    </div>
  );
};

export default UnSuccessWalletModal;
