import React from "react";
import { Qorra } from "../../utils/constant";
import toast from "react-hot-toast";

const PurchaseSuccessful = ({ setStep, receipt }) => {
  const handleButtonClick = (_receipt) => {
    window.open(
      `${process.env.REACT_APP_BLOCK_EXPLORER_BASE_URL}tx/${receipt}`,
      "_blank"
    ); // Replace with your URL
  };

  const handleImportToken = async () => {
    const tokenSymbol = "QOR";
    const tokenDecimals = 18;
    const tokenImage =
      "https://photos.pinksale.finance/file/pinksale-logo-upload/1718202213505-f50c73d00fda2bd6d78ce4082e70f008.png";

    try {
      // Check if MetaMask is installed
      if (typeof window.ethereum !== "undefined") {
        // Request to import the token
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: Qorra,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });

        if (wasAdded) {
          toast.success("Token added successfully");
        } else {
          toast.error("Token not added");
        }
      } else {
        toast.error("MetaMask is not installed");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="w-full">
      <div className="flex justify-center mb-4">
        <img
          src="./images/checkIcon.png"
          className="h-16 w-16 object-contain"
          alt="icon"
        />
      </div>
      <h1 className="capitalize text-[20px] md:text-[24px] font-bold text-center text-white">
        PURCHASE SUCCESSFUL
      </h1>
      <p className="text-[14pxpx] md:text-[18px] font-normal text-center text-white">
        Thank you for purchasing QOR tokens and joining the Qorra family!{" "}
      </p>
      <div className="flex flex-col gap-5 w-full mt-5">
        <button
          className="btn bg-themeColor2 text-[#161819] font-semibold text-base h-[52px] md:h-[60px] block w-full"
          onClick={handleButtonClick}
        >
          View Transaction
        </button>
        <button
          onClick={() => setStep(0)}
          className="btn bg-none font-semibold border-2 text-white text-base h-[52px] md:h-[60px] block w-full"
        >
          Purchase More
        </button>
        <button
          onClick={handleImportToken}
          className="btn bg-none font-semibold border-2 text-white text-base h-[52px] md:h-[60px] block w-full"
        >
          Import Token
        </button>
      </div>
    </div>
  );
};

export default PurchaseSuccessful;
